<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Tokens</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="tokens" @actions="dataGridActions" :select="seleccionar">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_tokens">Recargar</button>
			</div>
			<div class="col-sm-2">
				<button class="btn principal-btn" @click="abrir_modal(1)">Nuevo</button>
			</div>
			<div class="col-sm-2">
				<button class="btn secondary-btn" @click="abrir_modal(2)">Editar</button>
			</div>
			<div class="col-sm-2">
				<button class="btn danger-btn">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="modal != 0" @close="cancelar_modal" class="bpb-modal">
			<h2 class="title">Token</h2>
			<div class="body" style="height: 400px">
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
					<div class="col-sm-9"><input v-model="token.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label class="col-form-label col-sm-3" for="ip">
						<input v-model="enable_ip" class="mr-2" type="checkbox" name="enable_ip" id="enable_ip">
						<label class="check-label" for="enable_ip"> </label>
						Validación por IP
					</label>
					<div class="col-sm-9"><input v-model="token.ip" type="text" name="ip" id="ip" class="form-control" :disabled="!enable_ip"></div>
				</div>
				<div class="row form-group">
					<label class="col-form-label col-sm-3" for="exp">
						<input v-model="enable_exp" class="mr-2" type="checkbox" name="enable_exp" id="enable_exp">
						<label class="check-label" for="enable_exp"> </label>
						Fecha de expiración
					</label>
					<div class="col-sm-9">
						<datetime v-model="get_fecha_exp" input-class="form-control" :disabled="!enable_exp"></datetime>
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3">Roles</label>
					<div class="col-sm-9">
						<div class="container-fluid">
							<div class="row list-header">
								<div class="col-sm-10 text-center">Rol</div>
							</div>
							<div v-for="tmpRol in rolesSeleccionados" :key="tmpRol.id" class="row list-item">
								<div class="col-sm-10 text-center">{{ tmpRol.nombre }}</div>
								<div class="col-sm-2 text-center quit">
									<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" @click="quitar_rol(tmpRol.id)" title="Quitar rol">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button class="btn principal-btn" @click="abrir_modal_roles">Agregar rol</button>
						</div>
						<div class="col-sm-3">
							<button class="btn secondary-btn" @click="guardar_token">Guardar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modalRoles" @close="cancelar_modal_roles" class="bpb-modal">
			<h2 class="title">Agregar rol</h2>
			<div class="body">
				<div class="row form-group">
					<label for="rol" class="col-form-label col-sm-2">Rol</label>
					<div class="col-sm-10">
						<select v-model="rol.id" name="rol" id="rol" class="form-control">
							<option v-for="tmpRol in roles_validos" :key="tmpRol.id" :value="tmpRol.id">{{ tmpRol.nombre }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button class="btn principal-btn" @click="agregar_rol">Agregar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_modal_roles">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="lastView" @close="cerrar_view">
			<div class="title">Token creado</div>
			<div class="body">
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
					<div class="col-sm-9"><input v-model="nuevo_token.descripcion" type="text" name="descripcion" id="descripcion" class="form-control" readonly></div>
				</div>
				<div class="row form-group">
					<label class="col-form-label col-sm-3" for="ip">
						<input v-model="enable_ip" class="mr-2" type="checkbox" name="enable_ip" id="enable_ip">
						<label class="check-label" for="enable_ip"> </label>
						Validación por IP
					</label>
					<div class="col-sm-9"><input v-model="nuevo_token.ip" type="text" name="ip" id="ip" class="form-control" :disabled="!enable_ip"  readonly></div>
				</div>
				<div class="row form-group">
					<label class="col-form-label col-sm-3" for="exp">
						<input v-model="enable_exp" class="mr-2" type="checkbox" name="enable_exp" id="enable_exp">
						<label class="check-label" for="enable_exp"> </label>
						Fecha de expiración
					</label>
					<div class="col-sm-9">
						<datetime v-model="nuevo_token.exp" input-class="form-control" :disabled="!enable_exp"  readonly></datetime>
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3">Token</label>
					<div class="col-sm-9">{{ nuevo_token.token }}</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3">Roles</label>
					<div class="col-sm-9">
						<div class="container-fluid">
							<div class="row list-header">
								<div class="col-sm-10 text-center">Rol</div>
							</div>
							<div v-for="tmpRol in nuevo_token.roles" :key="tmpRol.id" class="row list-item">
								<div class="col-sm-10 text-center">{{ tmpRol.nombre }}</div>
								<div class="col-sm-2 text-center quit">
									<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" @click="quitar_rol(tmpRol.id)" title="Quitar rol">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-10"><button class="btn secondary-btn" @click="cerrar_view">Aceptar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import { Datetime } from 'vue-datetime'

	import 'vue-datetime/dist/vue-datetime.css'

	import api from '@/apps/account/api/account'

	export default {
		components: {
			DataGrid, Modal, Datetime
		}
		,data: function() {
			return  {
				dataGridConfig: {
					name: 'usuarios'
					,cols: {
						id: 'ID'
						,descripcion: 'Descripción'
						,token: 'Token'
						,exp: 'Fecha de expiración'
						,ip: 'Filtro IP'
						,estatus: 'Estatus'
					}
					,filters: {
						cols: {
							id: 'ID'
							,descripcion: 'Descripción'
							,token: 'Token'
							,exp: 'Fecha de expiración'
							,ip: 'Filtro IP'
							,estatus: {
								text: 'Estatus'
								,option: {
									1: 'Activo'
									,0: 'Inactivo'
								}
							}
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						exp: function(val) {
							return val ? val : 'Nunca';
						}
						,ip: function(val) {
							return val ? val : 'Cualquiera';
						}
						,estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccion: []
				,tokens: []
				,token: {
					descripcion: null
					,ip: null
					,exp: null
					,roles: []
				}
				,nuevo_token: {
					descripcion: null
					,ip: null
					,exp: null
					,roles: []
				}
				,roles: []
				,rol: {
					id: 0
					,nombre: null
				}
				,rolesSeleccionados: []
				,rolesSeleccionadosOriginales: []
				,modal: 0
				,enable_ip: false
				,enable_exp: false
				,modalRoles: false
				,lastView: false
			}
		}
		,mounted: function() {
			this.obtener_tokens();
		}
		,methods: {
			obtener_tokens: async function() {
				let response = (await api.listar_tokens(this.options)).data;

				this.tokens = response.data;

				this.dataGridConfig.paginator.pagina_actual = response.current_page;
				this.dataGridConfig.paginator.total_registros = response.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(response.per_page);
			}
			,obtener_tokens_roles: async function() {
				this.rolesSeleccionados = (await api.listar_tokens_roles(this.token.id)).data;
				this.rolesSeleccionadosOriginales = Array.from(this.rolesSeleccionados);
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.obtener_tokens();
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}

			//modal
			,abrir_modal: function(tipo) {
				if (tipo == 1)
					this.modal = 1;
				else
					if (this.seleccion.length > 0) {
						this.token = this.seleccion[0];

						if (this.token.exp)
							this.enable_exp = true;

						if (this.token.ip)
							this.enable_ip = true;

						this.obtener_tokens_roles();
						this.modal = 2;
					}else
						this.$helper.showMessage('Error','Necesitas seleccionar el token a editar', 'error', 'alert');
			}
			,cancelar_modal: function() {
				this.modal = 0;
				this.token = {
					descripcion: null
					,ip: null
					,exp: null
					,roles: []
				}
				this.enable_exp = false;
				this.enable_ip = false;
				this.rolesSeleccionados = [];
				this.seleccionar = false;
			}
			,quitar_rol: function(id) {
				let roles = [];

				for(let i=0; i<this.rolesSeleccionados.length; i++) {
					if (this.rolesSeleccionados[i].id != id)
						roles.push(this.rolesSeleccionados[i]);
				}

				this.rolesSeleccionados = roles;
			}
			,guardar_token: async function() {
				try {
					if (!this.enable_exp)
						delete this.token.exp;

					if (!this.enable_ip)
						delete this.token.ip;

					if (this.modal == 1) {
						this.nuevo_token = (await api.crear_token(this.token)).data;
						this.$log.info('tokennuevo: ', this.nuevo_token)
						this.lastView = true;
					}else {
						await api.editar_token(this.token.id, this.token);

						let nuevos = [],borrados = [];

						for(let i=0; i<this.rolesSeleccionados.length; i++) {
							let encontrado = false;

							for(let a=0; a<this.rolesSeleccionadosOriginales.length; a++) {
								if (this.rolesSeleccionados[i].id == this.rolesSeleccionadosOriginales[a].id) {
									encontrado = true;
									a = this.rolesSeleccionadosOriginales.length;
								}
							}

							if (!encontrado)
								nuevos.push(this.rolesSeleccionados[i].id);
						}

						for(let i=0; i<this.rolesSeleccionadosOriginales.length; i++) {
							let encontrado = false;

							for(let a=0; a<this.rolesSeleccionados.length; a++) {
								if (this.rolesSeleccionadosOriginales[i].id == this.rolesSeleccionados[a].id) {
									encontrado = true;
									a = this.rolesSeleccionados.length;
								}
							}

							if (!encontrado)
								borrados.push(this.rolesSeleccionadosOriginales[i].id);
						}

						if (nuevos.length > 0) {
							let res = await api.asignar_roles_token(this.token.id, {roles: nuevos});
							this.$log.info('asignar_roles: ', res);
						}

						if (borrados.length > 0)
							await api.desasignar_roles_token(this.token.id, {roles: borrados});
					}

					this.cancelar_modal();
					this.obtener_tokens();
				}catch(e) {
					this.$log.error('Error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}

			//modal rol
			,obtener_roles: async function() {
				if (this.roles.length == 0)
					this.roles = (await api.listar_roles({}, false)).data;

				this.rol.id = this.roles_validos[0].id;
			}
			,abrir_modal_roles: function() {
				this.obtener_roles();
				this.modalRoles = true;
			}
			,cancelar_modal_roles: function() {
				this.rol = {
					id: 0
					,nombre: null
				};
				this.modalRoles = false;
			}
			,agregar_rol: function() {
				let rolObj = document.getElementById('rol');
				this.rol.nombre = rolObj.options[rolObj.selectedIndex].text;
				this.$log.info('rol', this.rol);

				this.rolesSeleccionados.push(this.rol);
				this.token.roles.push(this.rol.id);

				this.cancelar_modal_roles();
			}
			,cerrar_view: function() {
				this.lastView = false;
				this.nuevo_token = {
					descripcion: null
					,ip: null
					,exp: null
					,roles: []
				};
			}
		}
		,computed: {
			roles_validos: function() {
				let roles = [];

				for(let i=0; i<this.roles.length; i++) {
					let encontrado = false;

					for(let a=0; a<this.rolesSeleccionados.length; a++) {
						if (this.roles[i].id == this.rolesSeleccionados[a].id) {
							encontrado = true;
							a = this.rolesSeleccionados.length;
						}
					}

					if (!encontrado)
						roles.push(this.roles[i]);
				}

				return roles;
			}
			,get_fecha_exp: {
				get: function() {
					this.$log.info('fecha1', this.token.exp);
					let fecha = this.$moment.utc(this.token.exp).add(1, 'days').format();
					this.$log.info('fecha2', fecha);
					return fecha;
				}
				,set: function(val) {
					this.$log.info('dia', val);
					this.token.exp = this.$moment(val).subtract(1, 'days');
				}
			}
		}
	}
</script>

<style lang="scss">
	.list-header {
		background-color: #0781cd;
		color: #fff;
	}
	.list-item {
		.quit {
			cursor: pointer;
		}

		img {
			width: 15px;
		}
	}
	.list-item:nth-child(2n+1) {
		background-color: #BFDCEE;
	}
</style>